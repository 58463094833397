/* This is the submit button styles */
.button-loading {
  /* display: block; */
  /* margin: 0 auto; */
  /* padding: .6em .8em; */
  /* Font-size is the root value that determines size of spinner parts. 
Change this to whatever you want and spinner elements will size to match. */
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* border-radius: .4em; */
  /* border: none; */
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 1s;
  color: white;
  padding: 8px;
  width: 6rem;
}

/* focus/disabled styles, you can change this for accessibility */
.button-loading:focus, .button-loading:disabled {
  outline: none;
  /* background: #aaa; */
  background-image: -webkit-linear-gradient(79deg,#aaa 0%,#a5a3a2 100%) !important;
  background-image: -moz-linear-gradient(79deg,#aaa 0%,#a5a3a2 100%) !important;
  background-image: -ms-linear-gradient(79deg,#aaa 0%,#a5a3a2 100%) !important;
  background-image: -o-linear-gradient(79deg,#aaa 0%,#a5a3a2 100%) !important;
  background-image: linear-gradient(-101deg,#aaa 0%,#a5a3a2 100%) !important;
}

.button-buy {
  background-color: green
}

.button-sell {
  background-color: red
}

/* This is the space for the spinner to appear, applied to the button */
.spin {
  padding-left: 2.5em;
  display: block;
}

/* position of the spinner when it appears, you might have to change these values */
.spin .spinner {
  left: -.6em;
  top: .4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

/* spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

/* The actual spinner element is a pseudo-element */
.spin .spinner::before {
  content: "";
  width: 1.5em; /* Size of the spinner */
  height: 1.5em; /* Change as desired */
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: solid .35em #999; /* Thickness/color of spinner track */
  border-bottom-color: #555; /* Color of variant spinner piece */
  animation: .8s linear infinite spinner; /* speed of spinner */
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* optional, but it will affect the size if changed */
/* *, *::before, *::after {
  box-sizing: border-box;
} */