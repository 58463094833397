    .order-root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1px;
    }

    .MuiTextField-root {
      margin: 8px !important;
      width: 60%;
    }

    .order-limitBox .MuiTextField-root {
      margin: 0px !important;
    }


    .MuiButtonBase-root {
      margin: 1px
    }

    .order-limitBox {
      margin-left: 8px;
      margin-right: auto;
    }

    .order-fcLimit {
      vertical-align: bottom !important;
    }

    .order-limit {
      width: 100px;
      opacity: inherit;
    }

    .order-leverage {
      width: 70px !important;
      margin-bottom: auto !important
    }
    .order-fixed {
      width: 100px !important;
      /* margin:0 !important; */
      background-color:rgb(244, 206, 208);
     }

     #simple-tabpanel-3 {
      position: relative;
     }

     /* .tradingview-widget-container div {
      position: absolute;
      height: 100%;
     } */