body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  display: table;
  margin: auto;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #817f7e;
}

/* breakpoint_responsive *****************************/
/* @media only screen and (min-width: 750px) and (max-width: 970px) {
  .order_table {
    width: 100%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 970px) {
  .order_table {
    width: 100%;
  }
} */
